<script lang="ts">
	import { createDialog } from 'svelte-headlessui';
	import { signOut, type User } from 'firebase/auth';
	import { auth } from '$lib/firebase';
	import authStore from '$lib/authStore';
	import { posthog } from '$lib/initPosthog';
	import Transition from 'svelte-transition';
	import LightDarkModeSwitcher from './LightDarkModeSwitcher.svelte';

	export let fixed: boolean = false;

	export let user: User | undefined | null = undefined;
	export let journeyId: string | undefined = undefined;
	export let userIsOwner: boolean | undefined = undefined;

	const dialog = createDialog({ label: 'Menu' });

	async function logout() {
		try {
			posthog.reset();
			await signOut(auth);
		} catch (error: any) {
			console.log(error);
		}
	}

	function handleWindowPointerDown(e: PointerEvent) {
		if (!$dialog.expanded) return;
		if (!e.target) return;
		if (!(e.target as HTMLElement).closest('#header-menu')) {
			$dialog.expanded = false;
		}
	}
</script>

<svelte:window on:pointerdown={handleWindowPointerDown} />

<div id="header-menu" class="flex flex-row z-20">
	<div class="flex flex-row justify-start items-center">
		<button
			aria-label="Menu"
			class="w-8 h-8 flex flex-row justify-center items-center text-md rounded shadow-md
			text-primary-600 bg-primary-50 border border-primary-200 hover:brightness-90
			dark:text-zinc-400 dark:border-zinc-800 dark:hover:border-zinc-700 dark:bg-zinc-900 dark:hover:bg-zinc-800 dark:hover:text-zinc-300 dark:shadow-sm"
			on:click={() => {
				$dialog.expanded = !$dialog.expanded;
			}}
		>
			<i class="fa-solid fa-bars"></i>
		</button>
		<Transition show={$dialog.expanded}>
			<div
				class="{fixed
					? 'fixed'
					: 'absolute'} top-10 left-2 w-52 h-fit flex flex-col justify-start items-center
				text-zinc-800 bg-primary-50 rounded border border-primary-200 text-xs shadow-md
				dark:text-zinc-100 dark:bg-navy-900 dark:border-zinc-800"
			>
				<div class="h-1.5"></div>
				{#if $authStore.isLoggedIn}
					<div class="w-[196px] h-[4rem] py-2 px-1 overflow-x-hidden overflow-y-hidden">
						Signed in as
						<div class="h-0.5"></div>
						<p>
							<b>{$authStore.user?.displayName ? $authStore.user?.displayName : ''}</b>
						</p>
						<p>
							{$authStore.user?.email}
						</p>
					</div>
				{:else}
					<div
						class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
						dark:bg-navy-900 dark:hover:brightness-150"
					>
						<a href="/login" class="flex flex-row justify-start items-center w-full py-2 px-1">
							<div class="w-6 flex flex-row justify-center items-center">
								<i class="fa-solid fa-sign-in text-zinc-800 dark:text-zinc-200"></i>
							</div>
							<div class="w-1.5"></div>
							Log in
						</a>
					</div>
				{/if}
				<div class="h-1.5"></div>
				<div class="w-full h-0 border-t border-primary-200 dark:border-zinc-800"></div>
				<div class="h-1.5"></div>
				<div
					class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
					dark:bg-navy-900 dark:hover:brightness-150"
				>
					<a href="/canvas" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-paintbrush text-cyan-600 dark:text-cyan-400"></i>
						</div>
						<div class="w-1.5"></div>
						Canvas
					</a>
				</div>
				<div class="h-1.5"></div>
				<div class="w-full h-0 border-t border-primary-200 dark:border-zinc-800"></div>
				<div class="h-1.5"></div>
				<div
					class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
					dark:bg-navy-900 dark:hover:brightness-150"
				>
					<a href="/journeys" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-sailboat text-stone-500 dark:text-[#dab488]"></i>
						</div>
						<div class="w-1.5"></div>
						Journeys
					</a>
				</div>
				<div class="h-1.5"></div>
				<div class="w-full h-0 border-t border-primary-200 dark:border-zinc-800"></div>
				<div class="h-1.5"></div>
				<div
					class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
					dark:bg-navy-900 dark:hover:brightness-150"
				>
					<a href="/library" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-images text-rose-500 dark:text-rose-400"></i>
						</div>
						<div class="w-1.5"></div>
						Image Library
					</a>
				</div>
				<div
					class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
					dark:bg-navy-900 dark:hover:brightness-150"
				>
					<a href="/library-3d" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-cubes text-rose-500 dark:text-rose-400"></i>
						</div>
						<div class="w-1.5"></div>
						3D Library
					</a>
				</div>
				<div
					class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
					dark:bg-navy-900 dark:hover:brightness-150"
				>
					<a href="/boards" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-thumbtack text-rose-500 dark:text-rose-400"></i>
						</div>
						<div class="w-1.5"></div>
						Boards
					</a>
				</div>
				<div class="h-1.5"></div>
				<div class="w-full h-0 border-t border-primary-200 dark:border-zinc-800"></div>
				<div class="h-1.5"></div>
				<div
					class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
					dark:bg-navy-900 dark:hover:brightness-150"
				>
					<a
						href="https://discord.gg/hbb2qD57hM"
						class="flex flex-row justify-start items-center w-full py-2 px-1"
						on:click={(e) => {
							e.preventDefault();
							posthog.capture('click_header_discord_link', {
								user_id: user?.uid,
								journey_id: journeyId,
								is_owner: userIsOwner
							});
							window.open('https://discord.gg/hbb2qD57hM', '_blank');
						}}
					>
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-brands fa-discord text-[#6d7dcd]"></i>
						</div>
						<div class="w-1.5"></div>
						Iliad Discord
					</a>
				</div>
				<div class="h-1.5"></div>
				<div class="w-full h-0 border-t border-primary-200 dark:border-zinc-800"></div>
				<div class="h-1.5"></div>
				<div
					class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
					dark:bg-navy-900 dark:hover:brightness-150"
				>
					<a
						href="/account-settings"
						class="flex flex-row justify-start items-center w-full py-2 px-1"
					>
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-user-gear text-emerald-600 dark:text-emerald-400"></i>
						</div>
						<div class="w-1.5"></div>
						Account Settings
					</a>
				</div>
				<div
					class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
					dark:bg-navy-900 dark:hover:brightness-150"
				>
					<a href="/plan" class="flex flex-row justify-start items-center w-full py-2 px-1">
						<div class="w-6 flex flex-row justify-center items-center">
							<i class="fa-solid fa-tags text-emerald-600 dark:text-emerald-400"></i>
						</div>
						<div class="w-1.5"></div>
						Pricing
					</a>
				</div>
				<div class="h-1.5"></div>
				<div class="w-full h-0 border-t border-primary-200 dark:border-zinc-800"></div>
				<div class="h-1.5"></div>
				<LightDarkModeSwitcher />
				{#if $authStore.isLoggedIn}
					<div class="h-1.5"></div>
					<div class="w-full h-0 border-t border-primary-200 dark:border-zinc-800"></div>
					<div class="h-1.5"></div>
					<div
						class="w-[196px] h-fit bg-primary-50 rounded hover:brightness-90
						dark:bg-navy-900 dark:hover:brightness-150"
					>
						<a
							href="/"
							class="flex flex-row justify-start items-center w-full py-2 px-1"
							on:click={logout}
						>
							<div class="w-6 flex flex-row justify-center items-center">
								<i class="fa-solid fa-sign-out text-gray-600 dark:text-zinc-400"></i>
							</div>
							<div class="w-1.5"></div>
							Sign out
						</a>
					</div>
				{/if}
				<div class="h-1.5"></div>
			</div>
		</Transition>
	</div>
</div>
