<script lang="ts">
	import { browser } from '$app/environment';

	// Change to use a string type for theme
	let theme: 'light' | 'dark' | 'system' = 'dark';

	function handleSwitchTheme() {
		// Cycle through the three themes
		if (theme === 'light') theme = 'dark';
		else if (theme === 'dark') theme = 'system';
		else theme = 'light';

		// Save to localStorage
		localStorage.setItem('theme', theme);

		// Apply the theme
		applyTheme(theme);
	}

	// Function to apply theme based on selection
	function applyTheme(selectedTheme: string) {
		if (selectedTheme === 'dark') {
			document.documentElement.classList.add('dark');
		} else if (selectedTheme === 'light') {
			document.documentElement.classList.remove('dark');
		} else if (selectedTheme === 'system') {
			// For system theme, check OS preference
			const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
			prefersDark
				? document.documentElement.classList.add('dark')
				: document.documentElement.classList.remove('dark');
		}
	}

	// Initialize theme on load
	if (browser) {
		const savedTheme = localStorage.getItem('theme');

		if (savedTheme === 'light' || savedTheme === 'dark' || savedTheme === 'system') {
			theme = savedTheme as 'light' | 'dark' | 'system';
		} else {
			// Default to system if no theme is saved
			theme = 'system';
		}

		// Apply the theme
		applyTheme(theme);
	}
</script>

<button
	on:click={handleSwitchTheme}
	class="w-[196px] h-fit p-2 bg-primary-50 dark:bg-navy-900 rounded
    hover:brightness-90 dark:hover:brightness-150 flex flex-row justify-start items-center"
	aria-label="Toggle theme"
>
	{#if theme === 'dark'}
		<i class="fas fa-moon text-zinc-600 dark:text-zinc-200"></i>
		<div class="w-2"></div>
		Toggle theme: Dark
	{:else if theme === 'light'}
		<i class="fas fa-sun text-zinc-600 dark:text-zinc-200"></i>
		<div class="w-2"></div>
		Toggle theme: Light
	{:else}
		<i class="fas fa-circle-half-stroke text-zinc-600 dark:text-zinc-200"></i>
		<div class="w-2"></div>
		Toggle theme: System
	{/if}
</button>
